import React from "react"

import { Button } from "antd"
import _map from "lodash/map"
import _size from "lodash/size"
import _take from "lodash/take"

import { IArticle } from "../../interfaces/article"
import ArticleCard from "../ArticleCard"

import "./styles.less"
interface Props {
  cardData: IArticle[]
}
const RecentArticlesDesk = ({ cardData }: Props): JSX.Element => {
  const CARD_LIMIT = 6

  const INITIAL_CARDS = _take(cardData, CARD_LIMIT)

  const [visibleCards, setVisibleCards] = React.useState(INITIAL_CARDS)
  const showMoreButton = _size(visibleCards) === _size(cardData)

  React.useEffect(() => {
    setVisibleCards(INITIAL_CARDS)
  }, [cardData])

  const handleShowMore = () => setVisibleCards(cardData)
  const titleSectionClassName =
    "pb-6 m-0 text-2.375xl text-maastrichtBlue lg:text-5.5xl font-bold leading-8 lg:leading-[64px]"
  const articleCardsWrapper =
    "flex flex-row pb-8 md:flex-wrap md:pb-0 lg:justify-start  article_wrapper"
  return (
    <section className="cards-wrapper main-container m-auto">
      <h4 className={titleSectionClassName}>
        Mais <span className="text-persianGreen">Recentes</span>
      </h4>

      <div className={articleCardsWrapper}>
        {_map(visibleCards, (card: IArticle) => (
          <ArticleCard {...card} key={card.id} />
        ))}
        {!visibleCards.length &&
          <h1 className="text-center text-2xl font-bold text-maastrichtBlue my-5">Nenhum resultado foi encontrado!</h1>
        }
      </div>

      {!showMoreButton && (
        <Button
          onClick={handleShowMore}
          className="mt-8 md:mt-0 w-[281px] h-[43px]"
        >
          Ver tudo
        </Button>
      )}
    </section>
  )
}

export default RecentArticlesDesk
