import React from "react"
import { useMedia } from "react-use-media"

import _map from "lodash/map"
import _take from "lodash/take"
import _size from "lodash/size"

import RecentArticlesMobile from "./RecenteArticlesMobile"
import RecentArticlesDesk from "./RecentArticlesDesk"
import { IArticleRaw } from "../../interfaces/article"
import formatArticleData from "../../utils/formatArticleData"

interface Props {
  articles: IArticleRaw[]
}

const RecentArticles = ({ articles }: Props): JSX.Element => {
  const isMobile = useMedia({ maxWidth: "767px" })

  const formatedArticleData = formatArticleData(articles)
  return (
    <>
      {isMobile && <RecentArticlesMobile cardData={formatedArticleData} />}
      {!isMobile && <RecentArticlesDesk cardData={formatedArticleData} />}
    </>
  )
}

export default RecentArticles
