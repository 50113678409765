import cx from "classnames"
import { graphql } from "gatsby"
import React from "react"

import { Helmet } from "react-helmet"
import Footer from "../../components/Footer"
import Menu from "../../components/Menu"
import NewsletterBlog from "../../components/NewsletterBlog"
import { IArticleRaw } from "../../interfaces/article"
import nodesToData from "../../utils/nodesToData"
import RecentArticles from "../../components/RecentArticles"

const gridClasses = cx("m-auto main-container")

const CasesPage = ({ data: WpPosts }: any): JSX.Element => {
  const [postsCases]: IArticleRaw[][] = nodesToData(WpPosts)
  const title = "Cases";
  
  return (
    <>
      <Helmet defaultTitle="eNe Soluções"
        title={title}
        titleTemplate="%s | eNe Soluções">
        {/* Primary Meta Tags  */}
        <title>Cases eNe Soluções</title>
        <meta name="title" content="Cases eNe Soluções" />
        <meta name="description" content="https://enesolucoes.com.br/cases" />

        {/* Open Graph / Facebook  */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://enesolucoes.com.br/cases" />
        <meta property="og:title" content="Cases eNe Soluções" />
        <meta property="og:description" content="https://enesolucoes.com.br/cases" />
 
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://enesolucoes.com.br/cases" />
        <meta property="twitter:title" content="Cases eNe Soluções" />
        <meta property="twitter:description" content="https://enesolucoes.com.br/cases" />
       
      </Helmet>
      <main className={gridClasses}>
        <Menu activeMenu="cases" blog={true} />
      </main>
      <section className="bg-white">
        <main className={cx(gridClasses, "pt-16")}>
          <div className="mt-10">
            {postsCases && <RecentArticles articles={postsCases} />}
          </div>
        </main>
      </section>
      <section className="flex newsletter">
        <NewsletterBlog />
      </section>
      <Footer blog={true} />
    </>
  )
}


export const query = graphql`
  query postsCases {
    postsCases: allWpPost(
        sort: { fields: date, order: DESC }
        filter:{
          categories: {
            nodes: { elemMatch: { name: { eq: "🚀 Cases" } } }
          }
        }
    ) {
      nodes {
        id
        uri
        title
        dateGmt
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            name
          }
        }
        author {
          node {
            name
            avatar {
              url
            }
          }
        }
        date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt")
        contentType {
          node {
            id
          }
        }
        commentCount
        excerpt
      }
    }
    allWpCategory {
      nodes {
        name
      }
    }
  }
`

export default CasesPage
