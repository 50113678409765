import { Button } from "antd"
import _map from "lodash/map"
import _size from "lodash/size"
import _take from "lodash/take"
import React from "react"
import { IArticle } from "../../interfaces/article"
import ArticleCard from "../ArticleCard"
import "./styles.less"
interface Props {
  cardData: IArticle[]
}

const RecentArticlesMobile = ({ cardData }: Props): JSX.Element => {
  const CARD_LIMIT = 6
  const INITIAL_CARDS = _take(cardData, CARD_LIMIT)
  const [visibleCards, setVisibleCards] = React.useState(INITIAL_CARDS)

  React.useEffect(() => {
    setVisibleCards(INITIAL_CARDS)
  }, [cardData])

  const showMoreButton = _size(visibleCards) === _size(cardData)
  const handleShowMore = () => setVisibleCards(cardData)
  return (
    <section className="cards-wrapper">
      <h4 className="pb-6 m-0 text-2.375xl text-maastrichtBlue lg:5.5xl font-bold leading-8 ml-4">
        Mais <span className="text-persianGreen">Recentes</span>
      </h4>

      <div className="flex flex-row  overflow-y-hidden overflow-x-scroll pb-8">
        {_map(visibleCards, (card: IArticle) => (
          <ArticleCard {...card} key={card.id} />
        ))}
        {!cardData.length &&
          <h1 className="text-center text-2xl font-bold text-maastrichtBlue mt-5">Nenhum resultado foi encontrado!</h1>
        }
      </div>
      {!showMoreButton &&
        <Button onClick={handleShowMore} className="w-[100%] mt-4 text-base font-bold">Ver tudo</Button>
      }
    </section>
  )
}

export default RecentArticlesMobile
