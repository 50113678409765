import { Skeleton } from "antd"
import { Link } from "gatsby"
import _truncate from "lodash/truncate"
import _get from "lodash/get"
import _head from "lodash/head"
import React from "react"
import { IArticle } from "../../interfaces/article"
import Badges from "../Badges"
import { formatDateToArticleCard } from "../../utils/date"
import { useMedia } from "react-use-media"
import { divide } from "lodash"

const ArticleCard = ({
  featuredImage,
  title,
  tag,
  author,
  createdAt,
  slug,
}: IArticle): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState(true)
  const isMobile = useMedia({ maxWidth: "767px" })
  const selectedItem = typeof window !== "undefined" ? localStorage.getItem('selected-category') : ""

  const cardTitleClassNames =
    "text-maastrichtBlue font-bold text-xl leading-[1.4] py-6 m-0 w-full md:text-xl lg:text-2xl lg:pt-6 lg:pb-4 xl:text-2xl xx:text-2.375xl lg:hover:text-persianGreen lg:transition-all lg:duration-500"
  const cardWrapperClassName =
    "cursor-pointer flex flex-col min-w-[278px] md:max-w-[350px] lg:max-w-[304px] xl:lg:max-w-[358px] xx:lg:max-w-[424px] first:ml-4 mr-5 md:first:ml-0 md:mr-0 md:mb-16 lg:mb-24"

    const imageClassName = "w-full h-full object-cover rounded-[1.25rem]"
  React.useEffect(() => {
    let isMounted = true
    if(!isMobile) setIsLoading(true)
    setTimeout(() => {
      if(isMounted) setIsLoading(false)
    }, 2000)
    return () => { isMounted = false }
  }, [selectedItem])

  const authorText = `Por: ${_get(author, "name")}`
  const articleLink = `/blog/artigo${slug}`
  const postDate = formatDateToArticleCard(createdAt)
  const firstTag = _head(tag)
  const tagIcon = firstTag?.icon || ""
  const regexIsThereEmoji = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/
  const regexResults = regexIsThereEmoji.test(tagIcon)
  return (
    <Skeleton
      loading={isLoading}
      active={isLoading}
      paragraph={isLoading}
      className={cardWrapperClassName}
    >
      <Link to={articleLink} className={`md:w-6/12 ${cardWrapperClassName}`}>
        <figure className="pb-6 lg:pb-[18px] mb-0 h-[230px]">
          <img src={featuredImage} className={imageClassName} />
        </figure>

        <div className="w-[fit-content]">
          {regexResults &&
            <Badges
              index={0}
              icon={firstTag?.icon}
              text={firstTag?.text || ""}
              bgColor="bg-persianGreen border-persianGreen"
              textWhite
            />
          }
          {!regexResults &&
            <Badges
              index={0}
              text={`${firstTag?.icon} ${firstTag?.text}` || ""}
              bgColor="bg-persianGreen border-persianGreen"
              textWhite
            />
          }
        </div>
        <h3 title={title} className={cardTitleClassNames}>
          {_truncate(title, { length: 62 })}
        </h3>
        {/* <p
          title={authorText}
          className="text-maastrichtBlue text-sm pb-4 m-0 lg:text-base mt-auto"
        >
          <strong>{authorText}</strong>
        </p> */}
        <p
          title={postDate}
          className="text-maastrichtBlue m-0 text-xs lg:text-sm"
        >
          {postDate}
        </p>
      </Link>
    </Skeleton>
  )
}

export default ArticleCard
